.startups {
  display: grid;
  grid-template-columns: repeat(2, minmax(26rem, 1fr));
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  justify-content: center;

  @media screen and (min-width: 768px) and (max-width: 990px) {
    // grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 37.5em) {
    grid-template-columns: 1fr;
  }
}
