.footer {
  padding-block: 5.5rem;

  @media screen and (max-width: 37.5em) {
    & > .container {
      flex-direction: column !important;
      justify-content: center !important;
    }
  }
}
