.btn {
  outline: none;
  font: inherit;
  font-size: 1.3rem;
  border-color: transparent;
  min-width: 90px;
  padding: 0.45em 0.9em;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
  transform-origin: left;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &-pry {
    background-color: $color-pry;
    color: #fff !important;
    border: none;

    &:hover,
    &:active {
      background-color: $color-pry-dark !important;
      color: #fff !important;

      & a {
        color: #fff !important;
        // text-decoration: underline;
      }
    }
    &:disabled {
      background-color: $color-pry-light !important;
    }

    &-dark {
      background-color: $color-pry-dark;
      color: #fff !important;

      &:hover {
        background-color: $color-pry !important;
      }
    }
  }

  &-text-pry {
    background-color: transparent;
    color: $color-pry;
    padding: 0 !important;
    border: none;

    &:hover {
      &,
      & * {
        color: $color-pry-dark;
        border-color: $color-pry-dark !important;
      }
    }
  }

  &-gray {
    color: #fff !important;
    background-color: #6a6a6a;

    &:hover {
      background-color: $color-pry;
    }
  }

  &-light {
    color: black !important;
  }

  &-curved {
    border-radius: 99999px;
  }

  // &-gray {
  //   background-color: #f0f0f0;
  //   color: $text-dark;
  //   font-weight: 500;

  //   &:hover,
  //   &:active,
  //   &:focus {
  //     color: black;
  //     background-color: #ddd !important;
  //   }
  // }

  &[class*='btn-outline'] {
    background-color: transparent;
    border: 1px solid #adadad;
    color: #000000;

    &:hover {
      border-color: #000000;
    }

    &:is(.btn-outline-pry) {
      // background-color: rgba($color-pry, 0.1);
      color: $color-pry;
      border-color: currentColor;
      border-width: 2px;
      font-weight: 500;

      &:hover {
        background-color: $color-pry-lighter;
        // color: #fff;
      }
    }

    &:is(.btn-outline-white) {
      border-color: #838383;
      color: #f1e6ff;

      &:hover {
        // border-color: $color-pry;
        color: $color-pry-light;
      }
    }

    &:is(.btn-outline-transp) {
      border: 1px solid currentColor;
      color: rgba(#fff, 0.6);

      &:hover {
        color: rgba(#fff, 0.8);
      }
    }

    &:is(.btn-outline-gray) {
      border: 1px solid #adadad;
      color: $text-color;

      &:hover {
        background-color: #f4f4f4;
        border-color: $color-pry-dark;
        color: $text-dark;
      }
    }

    &:is(.btn-outline-red) {
      color: #c2c2c2 !important;
      // border: 1px solid #863131;
      border: none !important;
      color: #e72d2d !important;

      &:hover {
        color: #ff0000 !important;
      }
    }
  }
  &--sm {
    min-width: unset;
    border-radius: 3px;
    font-size: 1.1rem;
  }
  &--xsm {
    // transform: scale(0.9);
    border-radius: 3px;
    font-size: 1rem;
    // padding: 7px 1.6em;
  }
  &--lg {
    font-size: 1.7rem;
  }

  &-bg-none {
    &,
    &:active {
      background-color: none;
    }
    &:hover {
      background-color: rgba(#000, 0.035);
      color: #000;
    }

    &.no-bg-hover {
      padding: 0;
      background-color: transparent !important;

      &:hover {
        color: $color-pry-light !important;
      }
    }
  }

  &-circle {
    all: unset;
    width: 3rem;
    height: 3rem;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 1px solid #ccc;
    display: grid;
    place-items: center;

    &--sm {
      width: 2rem;
      height: 2rem;
    }

    &:hover {
      background-color: #f8f8f8 !important;
    }
  }

  &-rounded {
    border-radius: 999px;
    padding-inline: 1.7em !important;
  }
  &--KR {
    background-color: $color-pry-dark !important;
    width: 3.5rem;
    max-width: 3.5rem;
    height: 3.5rem;
    max-height: 3.5rem;
    font-size: 1.3.5rem;
    font-family: 'Raleway', sans-serif;
    padding: 0 !important;
    min-width: unset;
    aspect-ratio: 1;
    border-radius: 50%;
    color: #fff !important;
  }
}
