@import '../../../src/sass/abstracts/variables';

.header {
  padding-bottom: 9rem !important;
  position: relative;
  // overflow: hidden;

  & > *:not(figure[class*='position-absolute']) {
    position: relative;
    z-index: 3;
  }

  & > figure[class*='position-absolute'] {
    left: 5%;
    bottom: 5%;
    transform: scale(0.5);
    // width: 90vw;
    // height: 50vh;

    @media screen and (max-width: 1000px) {
      left: 0;
      top: 10rem;
      // transform: scaleX(1.8) scaleY(2) translateX(8%) translateY(-3%);
      // display: none;
    }
    @media screen and (max-width: 56.25em) {
      display: none;
    }
  }
}

.container {
  // Tab portrait and smaller
  @media screen and (max-width: 56.25em) {
    flex-direction: column;
    align-items: center;
    gap: 5rem !important;
    text-align: center;

    & > * {
      margin-inline: auto !important;
    }
  }
}

.headerLeft {
  @media screen and (max-width: 56.25em) {
    margin-bottom: 3rem;

    & > * {
      text-align: center;
      margin-inline: auto;
    }
  }
}

.btnJoin {
  width: 210px;
  align-items: center;
  column-gap: 12px;
  text-align: left !important;
  font-family: 'Raleway', sans-serif !important;
  font-weight: 600;
  position: relative;
  z-index: 3;

  &::after {
    position: absolute;
    top: calc(100% + 5px);
    color: #7600ff;
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
  }

  @media screen and (max-width: 56.25em) {
    &::after {
      content: attr(data-chatroom-password);
    }
  }

  &:hover {
    background-color: #7600ff !important;
    grid-template-columns: 1fr;
    justify-items: center;

    & > *:not(:first-child) {
      display: none;
    }
    svg {
      color: yellow;
    }

    &::after {
      content: attr(data-chatroom-password);
    }
  }
}

.headerRight {
  flex: 1;

  .imgBox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 20rem repeat(2, 15rem);
    gap: 1.5rem;
    margin-right: auto;
    position: relative;
    z-index: 3;

    @media screen and (max-width: 56.25em) {
      gap: 1rem;
    }

    & > figure {
      display: block;
      height: 100%;
      // NEW
      width: 100%;
      position: relative;

      & > img {
        display: block;
        object-fit: cover;
        object-position: top center;
        width: 100%;
        height: 100%;
      }

      &:not(:nth-child(3), :nth-child(4)) {
        & > img {
          border-radius: 4px;
          // NEW
          position: absolute;
          inset: 0;
        }
      }
    }

    & > figure:nth-child(1) {
      grid-column: 1 / 4;
      grid-row: 1 / 2;
    }
    & > figure:nth-child(2) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }
    & > figure:nth-child(5) {
      grid-column: span 2;
    }
  }

  .social {
    position: relative;

    & > * {
      transition: opacity 0.2s;

      &:not(:first-child, small) {
        opacity: 0;
        position: relative;
        z-index: 2;
      }

      &:where(small) {
        position: absolute;
        left: 33px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 1;
      }
    }

    &:hover > * {
      &:not(small) {
        opacity: 1;
      }
      &:where(small) {
        opacity: 0;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.7s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  animation: fadeOut 0.7s 0.7s forwards;
}
