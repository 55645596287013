.textfield {
  outline: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  // min-width: 25ch;
  width: 100%;
  padding: 0.4em 0.8em;
  border: 1px solid transparent;
  border-radius: 3px;
  color: gray;
  transition: height 0.25s;

  &.bg-white {
    background-color: #fff;
  }

  &:focus {
    border: 1px solid $color-pry !important;
    box-shadow: none;
    box-shadow: 2px 2px 2px rgba(#000, 0.07) !important;
  }

  &.underline {
    border: none !important;
    border-bottom: 2px solid $color-pry !important;
    box-shadow: none !important;
    border-radius: 0;
  }

  &.is-invalid {
    border-color: #dc3545 !important;
  }

  &.border:not(.is-invalid) {
    border: 1px solid #adadad;
  }

  &-sm {
    font-size: 0.9em;
  }

  &::placeholder {
    color: rgba(#000, 0.5);
  }

  &:not(.is-invalid):hover {
    // border-color: gray;
  }
}
