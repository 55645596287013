.family-raleway {
  font-family: 'Raleway', system-ui, BlinkMacSystemFont, 'Segoe UI';
}
.list-style-none {
  list-style: none;
}

.m {
  &b {
    &-6 {
      margin-bottom: 4rem;
    }
    &-7 {
      margin-bottom: 5rem;
    }
    &-8 {
      margin-bottom: 6rem;
    }
  }

  &t {
    &-6 {
      margin-top: 4rem;
    }
    &-7 {
      margin-top: 5rem;
    }
    &-8 {
      margin-top: 6rem;
    }
  }

  &s {
    &-6 {
      margin-left: 4rem;
    }
    &-7 {
      margin-left: 5rem;
    }
    &-8 {
      margin-left: 6rem;
    }
  }

  &e {
    &-6 {
      margin-right: 4rem;
    }
    &-7 {
      margin-right: 5rem;
    }
    &-8 {
      margin-right: 6rem;
    }
  }
  &y {
    &-6 {
      margin-block: 4rem;
    }
    &-7 {
      margin-block: 5rem;
    }
    &-8 {
      margin-block: 6rem;
    }
  }
}

.p {
  &b {
    &-6 {
      margin-bottom: 4rem;
    }
    &-7 {
      margin-bottom: 5rem;
    }
    &-8 {
      margin-bottom: 6rem;
    }
  }
  &t {
    &-6 {
      margin-top: 4rem;
    }
    &-7 {
      margin-top: 5rem;
    }
    &-8 {
      margin-top: 6rem;
    }
  }
  &s {
    &-6 {
      margin-left: 4rem;
    }
    &-7 {
      margin-left: 5rem;
    }
    &-8 {
      margin-left: 6rem;
    }
  }
  &e {
    &-6 {
      margin-right: 4rem;
    }
    &-7 {
      margin-right: 5rem;
    }
    &-8 {
      margin-right: 6rem;
    }
  }
}

.section-pad {
  padding-block: 7rem;

  &-bottom {
    padding-bottom: 7rem;

    &-lg {
      padding-bottom: 12rem;
    }
  }
  &-top {
    padding-top: 7rem;

    &-lg {
      padding-top: 12rem;
    }
  }
}

.xy-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-halfwhite {
  background-color: rgba(#fff, 0.5);
}

.flex-gap-1 {
  gap: 0.7rem;
}
.flex-gap-2 {
  gap: 2.2rem;
}
.flex-gap-3 {
  gap: 3rem;
}

.thin-scrollbar,
.modal-body {
  @include thin-scrollbar;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.w-max-content {
  max-width: max-content;
}
.w-min-content {
  max-width: min-content;
}

.text-black {
  color: #000;
  font-weight: 500;
}

.color-pry {
  color: $color-pry;
}
.color-pry-dark {
  color: $color-pry-dark !important;
}
.color-white {
  color: #fff !important;
}

.border {
  &-pry {
    border: 1px solid $color-pry !important;

    &-dark {
      border-color: $color-pry-dark !important;
    }
  }
  &-white {
    border: 1px solid #fff !important;
  }
}

.bg-pry {
  background-color: $color-pry !important;
}

.bg-pry-light {
  background-color: $color-pry-light !important;
}

.bg-pry-lightest {
  background-color: $color-pry-lightest !important;
}

@media screen and (min-width: 1024px) {
  .bg-rocket{
    background-image: url('../../../public/img/arrow-rocket.png');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position-y: 75%;
  }
}

.bg-pry-dark {
  background-color: $color-pry-dark !important;
}

.bg-dark {
  background-color: $bg-dark !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.border-transparent {
  border-color: transparent !important;
}

.text-pry {
  color: $color-pry-dark;
}
.text-pry-dark {
  color: $color-pry-dark;
}

.text-light {
  color: #888888 !important;
}

.bg-halfwhite {
  background-color: rgba(#fff, 0.5);
}

.u-page-main-heading {
  font-size: 4rem;
  font-weight: 500;
  font-family: 'Baskervville', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #353535;
}

.thin-scrollbar,
.modal-body {
  @include thin-scrollbar;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.w-max-content {
  max-width: max-content;
}
.w-min-content {
  max-width: min-content;
}

.text-black {
  color: #000;
  font-weight: 500;
}

.text-hover-dark:hover {
  &,
  & * {
    color: #000 !important;
  }
}

.text-hover-underline:hover {
  text-decoration: underline;
}

.border-transparent {
  border-color: transparent !important;
}

.circular {
  width: 27px;
  height: 27px;
  @include grid-center;
  border-radius: 50%;

  &--1 {
    width: 30px;
    height: 30px;
  }
  &--2 {
    width: 33px;
    height: 33px;
  }
  &--3 {
    width: 33px;
    height: 33px;
  }
  &--4 {
    width: 35px;
    height: 35px;
  }
  &--sm {
    width: 17px;
    height: 17px;
  }
}
