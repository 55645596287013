@import '../../../sass/abstracts/variables';

.newsSubheading {
  position: relative;
  padding-left: 2.7rem;
  display: flex;
  align-items: center;
}
.leftBorder {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    inset-block: 0;
    width: 1.2rem;
    height: calc(100% + 3px);
    background-color: $color-pry;
  }

  &.thinBorder {
    &::before {
      width: 3px;
      background-color: #000;
      left: -2rem;
    }
  }
}

.joshuaFigure {
  // Tab-portrait and below
  @media screen and (max-width: 56.25em) {
    // align-items: center;

    & > figcaption {
      // margin-inline-start: 0;
    }
  }
}

.newsSectionContent {
  font-size: 1.745rem;
  font-family: 'Raleway', sans-serif;

  & > img {
    width: max(55%, 25rem);
    // height: max(35vw, 27rem);
    object-fit: cover;
    float: left;
    margin: 2.5rem;
    margin-left: 0;
  }

  & :where(figcaption) {
    font-family: unset !important;
    // letter-spacing: 0.01em;
  }
}

.step {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start !important;
  gap: 3rem;

  // Tab-portrait and below
  @media screen and (max-width: 56.25em) {
    grid-template-columns: 1fr;
  }

  & h4 {
    margin-bottom: 2rem;
  }

  figure {
    width: 100%;
    & img {
      width: 100%;
      height: 30rem;
      // display: block;
      // height: 100%;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}
.rStep {
  flex-direction: row-reverse !important;
}
