*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --bs-tooltip-font-size: 10px !important;
  scroll-behavior: unset;
}

html {
  font-size: 62.5%;
  // scroll-padding-top: 5rem;

  // Small desktops
  @media screen and (min-width: 75em) {
    // font-size: 65%;
  }
  // Large Desktops
  @media screen and (min-width: 100em) {
    // font-size: 70%;
  }
  // Portrait
  @media screen and (max-width: 56.25em) {
    // font-size: 60%;
  }
}

body {
  min-height: 100vh;
  font-size: max($default-fsize, 14px);
  color: $text-color !important;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  // font-weight: 500;
  position: relative;
}

.app-container {
  max-width: 960px;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  font: inherit;
  text-decoration: none;
}

.white-overlay::before {
  position: absolute;
  inset: 0;
  width: 100px;
  height: 100px;
  // background-color: rgba(#fff, 0.8);
  background-color: black !important;
  z-index: 2000;
}

.modal-body {
  position: relative;
}

.link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.dropdown-menu {
  margin-top: 1rem;
  border: none;
  box-shadow: 0 1px 5px rgba(#000, 0.15), 0 5px 22px rgba(#000, 0.17);
  z-index: 4;
  @include thin-scrollbar;

  &::after {
    position: absolute;
    right: 15px;
    bottom: 100%;
    rotate: 45deg;
    transform: translateY(90%);
    width: 15px;
    aspect-ratio: 1;
    background-color: #fff;
    z-index: -1;
  }

  & > .dropdown-item {
    padding: 0.7em 1.5em;
    padding-right: 1.7em !important;
    font-size: 1.3em !important;
    color: #000 !important;

    &:not(:last-child) {
      // margin-bottom: 5px;
    }

    &:hover {
      background-color: #ececec;
      color: #000;
    }
  }
}

.invalid-feedback {
  text-align: left;
}

textarea {
  height: 100px;
}

.tooltip {
  --bs-tooltip-bg: $color-pry-dark;
  --bs-tooltip-max-width: 500px !important;
  --bs-tooltip-font-size: 10px !important;
  --bs-tooltip-padding-x: 1.2rem;
  --bs-tooltip-padding-y: 0.4rem;
  border-radius: 3px;
}

.tooltip-inner {
  font-size: 13px;
}

.tooltip.white-tooltip {
  & > .tooltip-inner {
    font-size: 12.5px;
    border-radius: 3px;
    color: #000;
    background-color: #fff !important;
    line-height: 1.2;
  }

  &-light-border > .tooltip-inner {
    &,
    & * {
      background-color: #fff !important;
    }
    opacity: 1;
    border: 1px solid #ccc;
  }

  &-arrow {
    // background-color: transparent !important;
    border-top-color: #ccc !important;

    &::before {
      // background-color: transparent !important;
      border-top-color: #ccc !important;
    }
  }
}

hr {
  border-color: #e7eaf3;
}
