.newsUpdates {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 20rem 18rem;
  gap: 2rem;

  & > .newsItem {
    display: block !important;
    background-color: gray;
    border-radius: 6px;
    overflow: hidden;

    &:first-child {
      grid-column: 1 / span 2;
      grid-row: 1 / span 2;
    }
    &:nth-child(2) {
      grid-column: 3 / span 2;
      grid-row: 1 / 2;
    }
    &:nth-child(3) {
      grid-column: 3 / 4;
    }

    & > figure {
      width: 100%;
      height: 100%;
      background-size: cover;
      position: relative;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 500ms;
      }
      &:hover img {
        transform: scale(1.5);
      }

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      &:hover::before {
        background-image: linear-gradient(to top, #7600ff 0%, #bebebe00 30%) !important;
      }

      & > figcaption {
        position: absolute;
        bottom: 1rem;
        left: 1.5rem;
        color: rgba(#fff, 0.95);
        font-weight: 600;
        font-size: 1.8rem;
        text-shadow: 0 0 3px rgba(#000, 0.5);
        z-index: 2;

        & > small {
          font-family: 'Lato', sans-serif;
          color: rgba(#fff, 0.75);
        }
      }
    }
  }

  @media screen and (max-width: 56.25em) {
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: 30rem 20rem 17rem !important;

    & > .newsItem {
      &:first-child {
        grid-column: 1 / -1 !important;
        grid-row: 1 / 2 !important;
      }
      &:nth-child(2) {
        grid-column: 1 / -1 !important;
        grid-row: 2 / 3 !important;
      }
      &:nth-child(3) {
        grid-column: 2 / 3 !important;
        grid-row: 3 / 4 !important;
      }
    }
  }
}
