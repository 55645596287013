.newsItem {
  display: flex;
  align-items: center;
  gap: 2rem;

  @media screen and (max-width: 37.5em) {
    gap: 2.5rem;

    &:not(:last-of-type) {
      margin-bottom: 5rem !important;
    }
  }

  &:hover h4 {
    text-decoration: underline;
    // color: #7600ff;
  }

  & > figure {
    flex: 1;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #eeeeee;

    & > img {
      width: 100%;
      height: 25rem;
      object-fit: cover;
    }
  }

  & > article {
    font-size: 1.9rem;
    flex: 1;

    & > h4 {
      font-family: 'Raleway', sans-serif;
      font-size: 1em;
      max-width: 30ch;

      @media screen and (max-width: 56.25em) {
        max-width: unset;
      }
    }
    & > p {
      font-family: 'Raleway', sans-serif;
      font-size: 0.85em;
      line-height: 1.3em;
      max-width: 35ch;

      @media screen and (max-width: 56.25em) {
        max-width: unset;
      }
    }
    & > .date {
      font-size: 0.6em;
    }
  }

  // Tab portrait
  @media screen and (max-width: 56.25em) {
    flex-direction: column;
    gap: 1rem;

    & > figure {
      width: 100%;
    }
  }
}
