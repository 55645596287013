$color-pry: #7600ff;
$color-pry-light: #b48dff;
$color-pry-lighter: #e9ddff;
$color-pry-lightest: #faf6ff;
$color-pry-dark: #3f0088;
// $color-sec: ;
// $color-sec-lightest: ;
// $color-sec-dark: ;
// $color-sec-darker: ;

$text-color: #343434;
$text-dark: #2f2f2f;

$bg-dark: #151515;

$line-color: #e7eaf3;

$default-fsize: 1.5rem;
