@mixin circle($diameter, $bg, $color) {
  width: $diameter;
  height: $diameter;
  display: grid;
  place-items: center;
  background-color: $bg;
  color: $color;
  border-radius: 50%;
}

@mixin grid-center {
  display: grid !important;
  place-items: center;
}

@mixin flex-center {
  justify-content: center;
  align-items: center;
}

@mixin thin-scrollbar {
  &::-webkit-scrollbar {
    height: 5px; // for horizontal scrollbars
    width: 5px; // for vertical scrollbars
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
    // background-color: #cacaca;
    height: 7px;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #888888;
    height: 3px;
    border-radius: 999px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #666666;
  }
}

.light-scroll-thumb:not(:hover) {
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4 !important;
  }
}
