@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-up {
  animation: fade-up 1s 0.3s forwards;
}

@keyframes vertical-oscillate {
  0% {
    transform: translateY(-1.8rem);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1.8rem);
  }
}

.animation-vertical-oscillate {
  animation: vertical-oscillate 0.5s infinite forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 0.4s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  animation: fadeOut 0.4s 0.4s forwards;
}
