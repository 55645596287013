.h {
  &-1,
  &-2,
  &-3 {
    font-family: 'Raleway', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto;
  }
  &-1 {
    &--main {
      font-size: 4.4rem;
      line-height: 1.1em;
      font-weight: 700;
      font-family: 'Raleway', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto;
      color: $text-dark;

      @media screen and (max-width: 37.5em) {
        font-size: 4rem;
      }
    }
  }
  &-2 {
    font-size: 3rem !important;
    color: $color-pry;
    font-weight: 700;
  }
  &-3 {
    font-size: 2.2rem !important;
    color: $color-pry;
    font-weight: 700;
  }
}

.parag {
  display: block;
  line-height: 1.6em;
  max-width: 70ch;
  font-weight: normal;
  color: $text-color;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial;
}
