@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.dotFlashing {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -15px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &::after {
    left: 15px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }
}

.sm {
  transform: scale(0.85);
  // width: 5px !important;
  // height: 5px !important;

  // &::before,
  // &::after {
  //   content: '';
  //   width: 5px !important;
  //   height: 5px !important;
  // }
}

.lg {
  transform: scale(1.25);
  // width: 10px !important;
  // height: 10px !important;

  // &::before,
  // &::after {
  //   content: '';
  //   width: 10px !important;
  //   height: 10px !important;
  // }
}
