.startupCard {
  min-width: 25rem;
  background-color: #fff;
  border-bottom: 1px solid #d3d3d3;
  box-shadow: 0 1px 7px rgba(#000, 0.17);
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 0 7px 20px rgba(#000, 0.15);
    transform: translateY(-1.2rem) scale(1.05);
  }

  & > figure {
    width: 100%;
    height: 13rem;

    & > img {
      display: block;
      max-width: 90%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  & button {
    border-radius: 999px;
  }
}
